<template>
  <div>
    <Header class="space-mobile"></Header>
    <div class="container space">
      <div class="row">
        <div class="col-lg-6">
          <p><b>ADRESA</b></p>
          <p>MIDUR s.r.o.</p>
          <p>Masná 496/7d</p>
          <p>Trnitá, 602 00 Brno</p>
          <p>C 40640 vedená u Krajského soudu v Brně</p>
        </div>
        <div class="col-lg-6">
          <p><b>KONTAKT</b></p>
          <a href="mailto:midur.trubky@email.cz">midur.trubky@email.cz</a>
        </div>
      </div>
    </div>
    <Footer class=""/>
  </div>
</template>

<script>
import Header from "./Header";
import Footer from "./Footer";

export default {
  name: "ContactPage",
  components: {
    Footer,
    Header
  }
}
</script>

<style scoped>
.space {
  min-height: 70vh;
  padding-top: 12rem;
}

@media screen and (max-width: 600px) {
  .space-mobile {
    padding-bottom: 8rem;
  }
  .space {
    min-height: 50vh;
    padding-top: 2rem;
  }
}
</style>